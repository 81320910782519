import React, {useEffect, useState} from "react";

function FreeContent(props) {

  const [webAppIframeUrl] = useState("https://oneway-wetix.s3.eu-west-3.amazonaws.com/iframe/index.html");

  return (
    <>
      <div className="info_block">
        <iframe
          src={webAppIframeUrl}
          id="infoBlock"
          frameBorder="0"></iframe>
      </div>
    </>
  );
}

export {FreeContent};